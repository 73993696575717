import { Link } from "gatsby"
import React from "react"
import EducatorForm from "../components/forms/educator"
import Layout from "../components/layout"
import SEO from "../components/seo"
const educator = require("../images/educator.jpg")
const chalkboard = require("../images/chalkboard.jpg")

const EducatorPage = () => (
  <Layout>
    <SEO title="Educator" description="" lang="en" meta={[]} />
    {/* Educator Section */}

    <div className="title-banner educator">
      <h1>Educator</h1>
      <h3>
        Addressing important technical challenges facing our society today.
      </h3>
    </div>

    <section className="bg-darkened">
      <div className="container">
        <div className="row d-flex align-items-end">
          <div className="col-md-6 align-self-stretch d-flex flex-column justify-content-between">
            <div className="text-content">
              <h3 className="bold">Working with Flapmax</h3>
              <p>
                Our research interest spans a broad spectrum of software and
                hardware. We are especially interested in{" "}
                <Link
                  to="/focus"
                  className="hyperlink"
                  title="Read About Our Focus Areas"
                >
                  areas
                </Link>{" "}
                related to Applied AI, systems, and networking.
              </p>
            </div>

            <img src={educator} alt="" className="img-fluid" />
          </div>
          <div className="col-md-6">
            <EducatorForm purpose="Collaboration" />
          </div>
        </div>
        <div className="bg-darkened">
          <div className="container pt-5">
            <h3>Related Links</h3>
            <ul>
              <li>
                <Link to="/student">Students Page</Link>
              </li>
              <li>
                <Link to="/industry">Industry Page</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default EducatorPage
